import React, { useState, useRef, useEffect } from "react";
import { Wheel } from "react-custom-roulette";
import audio from "../assets/ding.mp3";

function Roulette(props) {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const ref = useRef(null);
  useEffect(() => {
    console.log("width", ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  const handleSpinClick = () => {
    let names = props.data.map((x) => {
      return x.option;
    });
    names = names.filter((x) => !props.out.includes(x));
    if (names.length <= 0) {
      alert("추첨 가능 데이터가 없습니다.");
      return;
    }

    let newPrizeNumber = Math.floor(Math.random() * props.data.length);
    while (props.out.includes(props.data[newPrizeNumber].option)) {
      newPrizeNumber = Math.floor(Math.random() * props.data.length);
    }

    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
    new Audio(audio).play();
  };

  return (
    <div class="box-roulette">
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={props.data}
        onStopSpinning={() => {
          setMustSpin(false);
          props.complete(props.data[prizeNumber].option);
          alert(props.data[prizeNumber].option + " 당첨");
        }}
        outerBorderWidth={0}
        radiusLineWidth={1}
        fontSize={16}
        textDistance={70}
        ref={ref}
      />
      {/* <div class="markers"></div> */}
      <button type="button" id="btn-start" onClick={handleSpinClick}>
        화순라이크
        <br />
        돌림판
        <br />
        {props.win.length > 0 && `(${props.win.length})`}
      </button>
    </div>
  );
}
export default Roulette;
