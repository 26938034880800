import logo from "./logo.svg";
import "./App.css";
import Roulette from "./roulette/roulette";
import title from "./assets/spin2.png";
import React from "react";

const arrayColors = [
  "#3f297e",
  "#1d61ac",
  "#169ed8",
  "#209b6c",
  "#60b236",
  "#efe61f",
  "#f7a416",
  "#e6471d",
  "#dc0936",
  "#e5177b",
  "#be107f",
  "#881f7e",
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      win: [],
      lose: [],
      tab: 1,
      inputData: "",
      outData:
        "1. 추첨 리스트에 이름이나 추첨하고자 하는 항목을 입력합니다. > 2. 구분은 컴마를 넣습니다. > 3. 중복으로 입력은 가능하나 중복 추첨은 하지 않음. > 4. 원판 중앙에 [화순라이크 돌림판]을 눌러 주세요",
    };
  }

  componentDidMount = () => {
    if (this.state.outData) {
      let array = this.state.outData.split(",");
      this.setState({ lose: array });
    }
    this.initRoulletteData();
  };

  initRoulletteData = () => {
    if (!this.state.inputData) {
      return;
    }

    let array = this.state.inputData.split(",");
    this.setState({
      data: array.map((x, idx) => {
        return {
          option: x,
          style: {
            backgroundColor: arrayColors[idx % arrayColors.length],
            textColor: "white",
          },
        };
      }),
    });
  };

  shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  render() {
    const { data, inputData, win, lose, outData, tab } = this.state;

    return (
      <div className="App">
        <p class="title">
          <img src={title} />
        </p>

        <Roulette
          data={data}
          win={win}
          out={win.concat(lose)}
          complete={(name) => {
            win.push(name);
            const array = this.state.inputData.split(",");
            let arrayFilter = array.filter((x) => x !== name);
            arrayFilter = this.shuffle(arrayFilter);
            this.setState({ inputData: arrayFilter.toString(), win }, (e) => {
              this.initRoulletteData();
            });
          }}
        />

        <div class="poll_list">당 첨: {win.toString()}</div>
        <div class="tabmenu">
          <ul>
            <li id="tab1" class="btnCon">
              {" "}
              <input
                type="radio"
                checked
                name="tabmenu"
                id="tabmenu1"
                checked={tab === 1}
                onChange={(e) => {
                  this.setState({ tab: 1 });
                }}
              />
              <label for="tabmenu1">추첨 리스트({data.length})</label>
              <div class="tabCon">
                <textarea
                  id="story1"
                  name="story1"
                  rows="5"
                  cols="33"
                  onChange={(e) => {
                    localStorage.setItem("inputData", e.target.value);
                    this.setState({ inputData: e.target.value }, (e) => {
                      this.initRoulletteData();
                    });

                    // data.push({ option: "홍길동" + i });
                    // setData()
                  }}
                  value={inputData}
                />
              </div>
            </li>
            <li id="tab2" class="btnCon">
              <input
                type="radio"
                name="tabmenu"
                id="tabmenu2"
                checked={tab === 2}
                onChange={(e) => {
                  this.setState({ tab: 2 });
                }}
              />
              <label for="tabmenu2"> 동작 설명서</label>
              <div class="tabCon">
                <textarea
                  id="story2"
                  name="story2"
                  rows="5"
                  cols="33"
                  onChange={(e) => {
                    localStorage.setItem("outData", e.target.value);
                    let array = e.target.value.split(",");
                    this.setState({ outData: e.target.value, lose: array });
                  }}
                  value={outData}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default App;
